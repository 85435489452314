<template>
  <div>
    <LinearLoader v-if="loading" absolute></LinearLoader>
    <div v-if="!loading" class="container-fluid mt-5">
      <div class="row" v-if="stats['total_users']">
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Users</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_users'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    <i class="fa fa-circle text-green" style="font-size: 8px"></i>
                    Users Online
                  </h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_users_online'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Basic Users</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['basic_users'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Premium Users</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['premium_users'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Promoters</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['promoters'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">
                    <i class="fa fa-circle text-green" style="font-size: 8px"></i>
                    Promoters Online
                  </h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['promoters_online'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Advertisers</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['advertisers'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New Users Today</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['new_users_today'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New Users Yesterday</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['new_users_yesterday'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New Users This Week</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['new_users_this_week'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New Users Last Week</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['new_users_last_week'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New Users This Month</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['new_users_this_month'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New Users Last Month</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['new_users_last_month'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New Promoter Users Today</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['new_promoter_users_today'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New Promoter Users Yesterday</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['new_promoter_users_yesterday'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New Promoter Users This Week</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['new_promoter_users_this_week'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New Promoter Users Last Week</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['new_promoter_users_last_week'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Feed Posts</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_feed_posts'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Blog Posts</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_blog_posts'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Forum Posts</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_forum_posts'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Job Posts</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_job_posts'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Posts</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_posts'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Blog Posts Unapproved</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_blog_posts_pending'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Forum Posts Unapproved</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_forum_posts_pending'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Job Posts Unapproved</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_job_posts_pending'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Posts Unapproved</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_posts_pending'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Blog Posts Approved</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_blog_posts_active'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Forum Posts Approved</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_forum_posts_active'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Job Posts Approved</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_job_posts_active'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Posts Approved</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_posts_active'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Transactions</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['transactions'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Subscriptions</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['subscriptions'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Withdrawals</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['withdrawals'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Tasks</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_tasks'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Active Tasks</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_active_tasks'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Completed Tasks</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_completed_tasks'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Pending Tasks</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_pending_tasks'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Paused Tasks</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_paused_tasks'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Pending Approval Tasks</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_pending_approval_tasks'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Cancelled Tasks</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_cancelled_tasks'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Withdrawals Vitisocial</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['withdrawals_vitisocial'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Pages</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_pages'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Verified Pages</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_verified_pages'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Groups</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_groups'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Total Verified Groups</h5>
                  <span class="h2 font-weight-bold mb-0">{{ stats['total_verified_groups'].toLocaleString() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="1 == 2">
          <div class="col-md-12 mb-3">
            <div class="row">
              <div class="col-md-3">
                <label for="filter" class="form-control-label">Filter by</label>
                <select v-model="filterBy" id="filter" class="form-control">
                  <option value="">None</option>
                  <option value="period">Period</option>
                  <option value="date">Date</option>
                </select>
              </div>
              <div v-if="filterBy === 'period'" class="col-md-3">
                <label for="period" class="form-control-label">Period</label>
                <select v-model="period" id="period" class="form-control">
                  <option v-for="n in 12" :key="n" :value="n">{{ n }} months</option>
                </select>
              </div>
              <template v-if="filterBy === 'date'">
                <div class="col-md-3">
                  <label for="start-date" class="form-control-label">Start date</label>
                  <input v-model="startDate" type="date" id="start-date" class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="end-date" class="form-control-label">End date</label>
                  <input v-model="endDate" type="date" id="end-date" class="form-control">
                </div>
              </template>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card card-stats">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Total Transactions</h5>
                    <span class="h2 font-weight-bold mb-0">NGN {{ Number(stats['transactions']).toLocaleString() }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card card-stats">
              <!-- Card body -->
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Total Withdrawals</h5>
                    <span class="h2 font-weight-bold mb-0">NGN {{ Number(stats['withdrawn']).toLocaleString() }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-md-6">
            <div class="card card-stats">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title text-uppercase text-muted mb-0">Total Subscriptions</h5>
                    <span class="h2 font-weight-bold mb-0">NGN {{ Number(stats['subscriptions']).toLocaleString() }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="1 == 2" class="row">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Users</h3>
                </div>
                <div class="col text-right">
                  <a href="#!" class="btn btn-sm btn-primary">See all</a>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <!-- Projects table -->
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                <tr>
                  <th scope="col">Page name</th>
                  <th scope="col">Visitors</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">
                    /argon/
                  </th>
                  <td>
                    4,569
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    /argon/index.html
                  </th>
                  <td>
                    3,985
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    /argon/charts.html
                  </th>
                  <td>
                    3,513
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">Transactions</h3>
                </div>
                <div class="col text-right">
                  <a href="#!" class="btn btn-sm btn-primary">See all</a>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <!-- Projects table -->
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                <tr>
                  <th scope="col">Referral</th>
                  <th scope="col">Visitors</th>
                  <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">
                    Facebook
                  </th>
                  <td>
                    1,480
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <span class="mr-2">60%</span>
                      <div>
                        <div class="progress">
                          <div class="progress-bar bg-gradient-danger" role="progressbar" aria-valuenow="60"
                               aria-valuemin="0" aria-valuemax="100" style="width: 60%;"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    Facebook
                  </th>
                  <td>
                    5,480
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <span class="mr-2">70%</span>
                      <div>
                        <div class="progress">
                          <div class="progress-bar bg-gradient-success" role="progressbar" aria-valuenow="70"
                               aria-valuemin="0" aria-valuemax="100" style="width: 70%;"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    Google
                  </th>
                  <td>
                    4,807
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <span class="mr-2">80%</span>
                      <div>
                        <div class="progress">
                          <div class="progress-bar bg-gradient-primary" role="progressbar" aria-valuenow="80"
                               aria-valuemin="0" aria-valuemax="100" style="width: 80%;"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ApiService from '@/services/api_service'

  export default {
    name: "Overview",
    data: () => ({
      filterBy: "",
      period: 1,
      stats: [],
      startDate: "",
      endDate: "",
      loading: true
    }),
    watch: {
      filterBy () {
        this.getStatistics()
      },
      period () {
        this.getStatistics()
      },
      startDate (val) {
        if (val) {
          this.getStatistics()
        }
      },
      endDate (val) {
        if (val) {
          this.getStatistics()
        }
      }
    },
    methods: {
      async getAllStats () {
        this.loading = true
        try {
          var apiResponse = await ApiService.AllStats()
          if(apiResponse['status'] == 'success'){
            this.stats = apiResponse['data']
          }else{
            this.notifyUser(apiResponse['status'],apiResponse['message'])
          }
        } catch (e) {
          let m = 'something went wrong'
          if(e){
            m = e['statusText']
          }
          this.notifyUser('error', m)
          // window.alert(e.message)
        } finally {
          this.loading = false
        }
      }
    },
    computed: {
    },
    mounted () {
      Promise.all([
        this.getAllStats()
      ]).finally(() => (this.loading = false))
    }
  }
</script>

<style scoped>

</style>
